import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
	errors: [],
	user: JwtService.getCurrentUser(),
	isAuthenticated: !!JwtService.getToken(),
	permissions: JwtService.getPermissions(),
};

const getters = {
	permissions(state) {
		return state.permissions;
	},
	authUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post("auth/login", credentials)
				.then(({ data }) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, data) {
		state.isAuthenticated = true;
		state.user = data.user;
		state.permissions = data.role.permissions;
		state.errors = {};
		JwtService.saveToken(data);
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.permissions = [];
		state.errors = [];
		JwtService.destroyToken();
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
